import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { getFAQ } from "../componente/API";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import { getHash } from "../componente/API";
import axios from "axios";
import SunEditor, { buttonList } from "suneditor-react";

const columns = [
	{ id: "idfaq", label: "#", minWidth: 40 },

	{ id: "intrebare", label: "Intrebare", minWidth: 100 },
	{
		id: "raspuns",
		label: "Raspuns",
		minWidth: 170,
		align: "left",
	},
	{ id: "modificare", label: "Modifica", minWidth: 100 },
];

const useStyles = makeStyles({
	root: {
		width: "100%",
	},
	container: {
		border: "1px solid rgba(0,0,0,.1)",
		marginTop: 30,
	},
});

const FAQ = () => {
	const [faqs, setFaqs] = useState("");
	useEffect(() => {
		getFAQ(setFaqs);
	}, []);
	const classes = useStyles();
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);
	const [faq, setFaq] = useState("");
	const [rsp, setRsp] = useState("");

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};
	const stergeIntrebare = async (id) => {
		var r = window.confirm("Sigur vrei sa stergi?");
		if (r) {
			let axiosConfig = {
				headers: {
					"content-type": "multipart/form-data",
				},
			};

			const data = new FormData();
			data.append("stergeFaq", id);

			await axios
				.post(
					`https://api.osut.ro/insert.php?token=${getHash()}`,
					data,
					axiosConfig
				)
				.then(function (response) {
					console.log(response.data);
					getFAQ(setFaqs);
				})
				.catch(function (error) {
					console.log(error);
				});
		}
	};
	const submit = async () => {
		if (faq === "" || rsp === "") return;
		let axiosConfig = {
			headers: {
				"content-type": "multipart/form-data",
			},
		};

		const data = new FormData();
		data.append("faq", faq);
		data.append("raspuns", rsp);

		await axios
			.post(
				`https://api.osut.ro/insert.php?token=${getHash()}`,
				data,
				axiosConfig
			)
			.then(function (response) {
				console.log(response.data);
				getFAQ(setFaqs);
				setFaq("");
				setRsp("");
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	return (
		<>
			<Grid item xs={12}>
				<Typography variant="h4">Intrebari frecvente</Typography>
				<TableContainer className={classes.container}>
					<Table stickyHeader aria-label="sticky table">
						<TableHead>
							<TableRow>
								{columns.map((column) => (
									<TableCell
										key={column.id}
										align={column.align}
										style={{ minWidth: column.minWidth }}>
										{column.label}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{faqs !== "" &&
								faqs
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((row) => {
										return (
											<TableRow
												hover
												role="checkbox"
												tabIndex={-1}
												key={row.code}>
												{columns.map((column) => {
													const value = row[column.id];
													return (
														<TableCell key={column.id} align={column.align}>
															{column.format && typeof value === "number"
																? column.format(value)
																: value}
															{column.id === "modificare" && (
																<Button
																	variant="contained"
																	color="primary"
																	onClick={() => stergeIntrebare(row["idfaq"])}>
																	Sterge
																</Button>
															)}
														</TableCell>
													);
												})}
											</TableRow>
										);
									})}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[5, 10, 25]}
					component="div"
					count={faqs.length}
					rowsPerPage={rowsPerPage}
					page={page}
					labelRowsPerPage="Randuri pe pagina"
					onChangePage={handleChangePage}
					onChangeRowsPerPage={handleChangeRowsPerPage}
				/>

				<Grid item xs={12} md={8} style={{ marginTop: 60 }}>
					<div id="insertFAQ">
						<Typography variant="h5" gutterBottom>
							Inserare
						</Typography>
						{rsp}
						<br />
						<TextField
							variant="outlined"
							fullWidth={true}
							label="Intrebare"
							value={faq}
							onChange={(e) => {
								setFaq(e.target.value);
							}}
							autoComplete={false}
						/>

						<SunEditor
							width="100%"
							placeholder="Conținutul articol"
							onChange={setRsp}
							setOptions={{
								charCounter: true,
								height: "auto",
								minHeight: "600px",
								maxHeight: "6250px",
								buttonList: [
									[
										"undo",
										"redo",
										"formatBlock",
										"bold",
										"underline",
										"italic",
										"strike",
										"image",
										"video",
										"fullScreen",
										"showBlocks",
										"codeView",
										"preview",
										"table",
										"link",
									],
								],
							}}
						/>
						<Button
							style={{ marginTop: 20 }}
							color="primary"
							size="large"
							onClick={submit}
							variant="contained">
							Inserare
						</Button>
					</div>
				</Grid>
			</Grid>
		</>
	);
};

export default FAQ;
