import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Grid from "@material-ui/core/Grid";
import { NavLink } from "react-router-dom";
import { getHash, checkHash, setHash } from "../componente/API";
import Login from "../pagini/Login";
import osut from "../img/logo-osut.png";
import ListIcon from "@material-ui/icons/List";
import FeedbackIcon from "@material-ui/icons/Feedback";
import CategoryIcon from "@material-ui/icons/Category";
import AddIcon from "@material-ui/icons/Add";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import SettingsIcon from "@material-ui/icons/Settings";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		background: "rgba(255,255,255,.0)",
		boxShadow: "none",
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
	},
	drawerContainer: {
		overflow: "auto",
		marginTop: 0,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
}));

export default function Header({ children }) {
	const classes = useStyles();
	const [autentificat, setAuth] = useState(false);
	const [rsp, srsp] = useState(null);
	useEffect(() => {
		const hash = getHash();
		checkHash(hash, srsp, (r) => {
			if (r.status === "success") setAuth(true);
			console.log(r);
		});
		if (autentificat) {
			console.log("autentificat");
			alert("header autentificat");
		}
	}, []);
	return (
		<>
			{autentificat ? (
				<>
					<div className={classes.root}>
						<CssBaseline />
						<AppBar
							position="fixed"
							color="default"
							style={{ pointerEvents: "none" }}
							className={classes.appBar}>
							<Toolbar>
								<Typography variant="h6" color="textPrimary" noWrap>
									{/* Admin panel */}
									<img
										src={osut}
										style={{
											height: "40px",
											margin: "5px 0px",
											marginTop: "5px",
											display: "block",
										}}
										alt="Admin panel OSUT"
									/>
								</Typography>
							</Toolbar>
						</AppBar>
						<Drawer
							className={classes.drawer}
							variant="permanent"
							id="meniu_global"
							classes={{
								paper: classes.drawerPaper,
							}}>
							<Toolbar />
							<div className={classes.drawerContainer}>
								<List>
									<ListItem>
										<Typography variant="body1" color="textSecondary" noWrap>
											Blog
										</Typography>
									</ListItem>
									<NavLink activeClassName="selected" to="/" exact>
										<ListItem button>
											<ListItemIcon>
												<ListIcon />
											</ListItemIcon>
											<Typography variant="body2" color="textPrimary" noWrap>
												Lista postari
											</Typography>
										</ListItem>
									</NavLink>
									<NavLink activeClassName="selected" to="/inserare-articol">
										<ListItem button>
											<ListItemIcon>
												<AddIcon />
											</ListItemIcon>
											<Typography variant="body2" color="textPrimary" noWrap>
												Inserare articol
											</Typography>
										</ListItem>
									</NavLink>
									<NavLink activeClassName="selected" to="/feedback">
										<ListItem button>
											<ListItemIcon>
												<FeedbackIcon />
											</ListItemIcon>
											<Typography variant="body2" color="textPrimary" noWrap>
												Mesaje feedback
											</Typography>
										</ListItem>
									</NavLink>
								</List>
								<Divider />
								<List>
									<ListItem>
										<Typography variant="body1" color="textSecondary" noWrap>
											Informatii studenti
										</Typography>
									</ListItem>

									<NavLink activeClassName="selected" to="/informatii" exact>
										<ListItem button>
											<ListItemIcon>
												<ListIcon />
											</ListItemIcon>
											<Typography variant="body2" color="textPrimary" noWrap>
												Lista informatii
											</Typography>
										</ListItem>
									</NavLink>
									<NavLink activeClassName="selected" to="/informatii-studenti">
										<ListItem button>
											<ListItemIcon>
												<CategoryIcon />
											</ListItemIcon>
											<Typography variant="body2" color="textPrimary" noWrap>
												Categorii
											</Typography>
										</ListItem>
									</NavLink>
									<NavLink activeClassName="selected" to="/inserare-informatie">
										<ListItem button>
											<ListItemIcon>
												<AddIcon />
											</ListItemIcon>
											<Typography variant="body2" color="textPrimary" noWrap>
												Inserare informatie (WIP)
											</Typography>
										</ListItem>
									</NavLink>
									<NavLink activeClassName="selected" to="/faq">
										<ListItem button>
											<ListItemIcon>
												<LiveHelpIcon />
											</ListItemIcon>
											<Typography variant="body2" color="textPrimary" noWrap>
												FAQ
											</Typography>
										</ListItem>
									</NavLink>
								</List>
								<Divider />

								<List>
									<ListItem>
										<Typography variant="body1" color="textSecondary" noWrap>
											Parteneri
										</Typography>
									</ListItem>
									<NavLink activeClassName="selected" to="/parteneri" exact>
										<ListItem button>
											<ListItemIcon>
												<ListIcon />
											</ListItemIcon>
											<Typography variant="body2" color="textPrimary" noWrap>
												Parteneri
											</Typography>
										</ListItem>
									</NavLink>
								</List>
								<Divider />

								<List>
									<ListItem>
										<Typography variant="body1" color="textSecondary" noWrap>
											Setari
										</Typography>
									</ListItem>
									<NavLink activeClassName="selected" to="/setari">
										<ListItem button>
											<ListItemIcon>
												<SettingsIcon />
											</ListItemIcon>
											<Typography variant="body2" color="textPrimary" noWrap>
												Setari generale
											</Typography>
										</ListItem>
									</NavLink>
								</List>
								<Divider />
								<List>
									<ListItem
										button
										onClick={() => {
											setHash("");
											window.location.reload();
										}}>
										<ListItemIcon>
											<ExitToAppIcon />
										</ListItemIcon>
										<ListItemText primary={"Iesire"} />
									</ListItem>
								</List>
							</div>
						</Drawer>
						<main className={classes.content}>
							<Grid
								container
								spacing={5}
								direction="row"
								alignItems="flex-start">
								{children}
							</Grid>
						</main>
					</div>
				</>
			) : (
				<Login />
			)}
		</>
	);
}
