import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import CardActionArea from "@material-ui/core/CardActionArea";
import MUILink from "@material-ui/core/Link";
import Card from "@material-ui/core/Card";
import { useHistory } from "react-router-dom";
import IntlRelativeFormat from "intl-relativeformat";

import "./post.css";

export const slugify = (string) => {
	// made string URL-friendly
	string += "";
	return string
		.toString() // Convert to string
		.normalize("NFD") // Change diacritics
		.replace(/[\u0300-\u036f]/g, "") // Remove illegal characters
		.replace(/\s+/g, "-") // Change whitespace to dashes
		.toLowerCase() // Change to lowercase
		.replace(/&/g, "-and-") // Replace ampersand
		.replace(/[^a-z0-9\-]/g, "") // Remove anything that is not a letter, number or dash
		.replace(/-+/g, "-") // Remove duplicate dashes
		.replace(/^-*/, "") // Remove starting dashes
		.replace(/-*$/, "");
};

const truncate = (input) => {
	input += "";
	return input.length > 130 ? `${input.substring(0, 140)}...` : input;
};

const useStyles = makeStyles({
	root: {
		minWidth: 275,
		borderRadius: "10px",
		height: "100%",
		margin: "30px, 0px",
		maxWidth: "375px",
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		transition: "all .2s",
		"&:last-child": {
			paddingBottom: 24,
		},
		boxShadow: "none",
		borderColor: "rgba(29,34,46,.1)",
		background: "rgba(255,255,255,.04)",
		transition: "transform .3s",
		"&:hover": {
			//transform: "translateY(7px)",
			"&>img": {
				opacity: 0,
			},
		},
	},
	bullet: {
		display: "inline-block",
		margin: "0 2px",
		transform: "scale(0.8)",
	},
	title: {
		fontSize: 14,
	},
	pos: {
		marginTop: -30,
		fontSize: "13px",
		opacity: 1,
		textAlign: "left",
	},
});

const Post = ({
	video = "",
	culoare_categorie,
	timestamp,
	view = "grid",
	loading = false,
	test = false,
	titlu,
	descriere_scurta,
	continut,
	id_autor,
	type,
	id,
	denumire_categorie,
	poza,
}) => {
	const history = useHistory();
	const classes = useStyles();
	const [hovered, setHovered] = useState(false);
	const titluArticol = useRef(null);
	const bull = <span className={classes.bullet}>•</span>;
	let vid = useRef(null);
	let ago = "";
	let poz = poza;
	function goToPost() {
		//if (!test) history.push("/p/" + id + "/" + slugify(titlu));
	}
	useEffect(() => {
		if (hovered && vid.current) vid.current.play();
		else if (vid.current) vid.current.pause();
	}, [titluArticol, hovered, vid]);

	if (video !== "") {
		return (
			<>
				<Card
					className={classes.root + " thepostcard video"}
					variant="outlined"
					id="cardz"
					onMouseEnter={() => setHovered(true)}
					onMouseLeave={() => setHovered(false)}>
					<div className="timp">{ago}</div>

					<video
						ref={vid}
						height="100%"
						style={{
							height: "100%",
							position: "absolute",
							left: "-50%",
							top: "0px",
						}}
						autoplay={true}
						loop={true}>
						<source src={video} type="video/mp4" />
						Your browser does not support the video tag.
					</video>
					<div className="descrierea">
						<div className="text">
							<Link style={{ textDecoration: "none" }}>
								<MUILink color="textPrimary" underline="none">
									<Typography
										variant="h5"
										component="h2"
										style={{ padding: "10px 10px 5px 10px" }}
										dangerouslySetInnerHTML={{
											__html: titlu,
										}}></Typography>

									<Typography
										variant="body1"
										color="textPrimary"
										component="p"
										dangerouslySetInnerHTML={{
											__html: truncate(descriere_scurta),
										}}
										style={{ padding: "00px 10px" }}></Typography>
								</MUILink>
							</Link>
						</div>
					</div>
				</Card>
			</>
		);
	} else {
		if (view === "grid") {
			return (
				<>
					<Card
						className={classes.root + " thepostcard"}
						variant="outlined"
						id="cardz">
						<div className="timp">{ago}</div>

						<MUILink color="textPrimary" underline="none">
							<div style={{ paddingTop: "0px" }}>
								{poz !== "" && (
									<img
										alt={titlu}
										src={poz}
										style={{
											width: "100%",
											borderRadius: "10px 10px 0px 0px",
											border: "none",
											boxShadow: "none",
										}}
										className="pozaArticol"
									/>
								)}
								<Typography
									variant="h5"
									component="h2"
									style={{ padding: "20px 10px 5px 20px" }}
									dangerouslySetInnerHTML={{
										__html: titlu,
									}}></Typography>

								<Typography
									variant="body1"
									color="textSecondary"
									component="p"
									dangerouslySetInnerHTML={{
										__html: truncate(descriere_scurta),
									}}
									style={{ padding: "0px 20px" }}></Typography>
							</div>
						</MUILink>
					</Card>
				</>
			);
		} else {
			return (
				<div color="textPrimary" className="post_horizontal">
					<CardActionArea onClick={() => goToPost()} className={classes.root}>
						<Link to={"#"}>
							<img alt={titlu} src={poza} />
							<p>
								<b>{denumire_categorie}</b>
							</p>
						</Link>
						<div className="info">
							<Typography
								color="textPrimary"
								variant="h6"
								component="h1"
								style={{ textTransform: "none", marginBottom: "20px" }}
								gutterBottom>
								{titlu}
							</Typography>
							<Typography
								color="textSecondary"
								variant="body1"
								gutterBottom
								dangerouslySetInnerHTML={{ __html: truncate(descriere_scurta) }}
							/>
						</div>
						<Typography
							color="textSecondary"
							variant="body2"
							component="small"
							style={{
								textTransform: "none",
								position: "absolute",
								bottom: "16px",
								right: "16px",
								opacity: 0.6,
							}}>
							{ago}
						</Typography>
					</CardActionArea>
				</div>
			);
		}
	}
};
export default Post;
