import React from "react";
import Grid from "@material-ui/core/Grid";
import TabelPostari from "../componente/TabelPostari";
import Typography from "@material-ui/core/Typography";
const Home = () => {
	console.log("home");

	return (
		<>
			<Grid item xs={6} md={12}>
				<Typography variant="h4">Postari blog</Typography>
				<TabelPostari />
			</Grid>
		</>
	);
};

export default Home;
