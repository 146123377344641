import axios from "axios";
import IntlRelativeFormat from "intl-relativeformat";
import { getCookie, setCookie } from "react-use-cookie";

export const getHash = () => {
	return getCookie("hash");
};
export const setHash = (hash) => {
	setCookie("hash", hash);
};

const urlAPI = `https://api.osut.ro/`;

export const getParteneri = (callback) => {
	const url = `${urlAPI}?getParteneri`;
	axios.get(url).then((res) => {
		callback(res.data);
	});
};

export const stergePartener = (id, callback) => {
	const url = `${urlAPI}remove.php?stergePartener=${id}&token=${getHash()}`;
	axios.get(url).then((res) => {
		callback(res.data);
	});
};

export const updateInformatieStudenti = async (
	id,
	titlu,
	continut,
	categorie,
	atasamente,
	callback
) => {
	const $url = `${urlAPI}update.php?token=${getHash()}&updateInformatieStudenti=${id}`;
	let axiosConfig = {
		headers: {
			"content-type": "multipart/form-data",
		},
	};

	const data = new FormData();
	data.append("titlu", titlu);
	data.append("continut", continut);
	data.append("categorie", categorie);

	for (let i = 0; i < atasamente.length; i++) {
		data.append("atasamente[]", atasamente[i]);
	}

	await axios
		.post($url, data, axiosConfig)
		.then(function (response) {
			callback(response.data);
		})
		.catch(function (error) {
			console.log(error);
		});
};

export const stergeInformatieStudenti = async (id, callback) => {
	const url = `${urlAPI}remove.php?informatieStudenti=${id}&token=${getHash()}`;
	await axios.get(url).then((res) => {
		callback(res.data);
	});
};

export const getMesajeFeedback = async (callback) => {
	const url = `${urlAPI}insert.php?getMesajeFeedback&token=${getHash()}`;
	console.log(url);
	await axios.get(url).then((res) => {
		callback(res.data);
	});
};

export const getAtasamenteInformatie = async (id, callback) => {
	const url = `${urlAPI}?getAtasamenteInformatie=${id}`;
	await axios.get(url).then((res) => {
		callback(res.data);
	});
};

export const getInformatii = async (setItems, callback) => {
	const url = `${urlAPI}?getInformatii`;
	await axios.get(url).then((res) => {
		setItems(res.data);
		callback(res.data);
	});
};

export const getFeedback = async (getFeedback, callback) => {
	const url = `${urlAPI}?getFeedback=${getFeedback}`;
	await axios.get(url).then((res) => {
		callback(res.data);
	});
};

// update categorie informatii studenti
export const updateInformatie = (info, callback) => {
	const url = `${urlAPI}insert.php?token=${getHash()}&updateInformatie=${
		info.id
	}&denumire=${info.denumire}&desc=${info.descriere}`;
	axios.get(url).then((res) => {
		callback(res);
		console.log(info);
	});
};

export const categorieInformatii = (id, callback) => {
	const url = `${urlAPI}?categorieInformatii=${id}`;
	axios.get(url).then((res) => {
		callback(res.data.titlu, res.data.descriere);
	});
};

export const stergeCategorieInformatii = (id, callback) => {
	const hash = getHash();
	const url = `${urlAPI}remove.php?token=${hash}&stergeCategorieInformatii=${id}`;
	axios.get(url).then((res) => {
		callback(res.data);
	});
};

export const stergeAtasamentInformatii = (id, callback) => {
	const hash = getHash();
	const url = `${urlAPI}remove.php?token=${hash}&stergeAtasamentInformatii=${id}`;
	axios.get(url).then((res) => {
		callback(res.data);
	});
};

export const stergeAtasamentBlog = (id, callback) => {
	const hash = getHash();
	const url = `${urlAPI}remove.php?token=${hash}&stergeAtasamentBlog=${id}`;
	axios.get(url).then((res) => {
		callback(res.data);
	});
};

export const stergeArticolBlog = (id, callback) => {
	const hash = getHash();
	const url = `${urlAPI}remove.php?token=${hash}&stergeArticol=${id}`;
	axios.get(url).then((res) => {
		callback(res.data);
	});
};

export const checkHash = (hash, setResponse, callback) => {
	const url = `${urlAPI}auth.php?hash=${hash}`;
	axios.get(url).then((res) => {
		setResponse(res.data);
		callback(res.data);
	});
};

export const Auth = (token, setResponse, callback) => {
	const url = `${urlAPI}auth.php?token=${token}`;
	axios.get(url).then((res) => {
		setResponse(res.data);
		callback(res.data);
	});
};

export const getFAQ = (setFAQ) => {
	const url = `${urlAPI}?faq`;
	axios.get(url).then((res) => {
		setFAQ(res.data[0]);
	});
};

export const getFAQAll = (setFAQ) => {
	const url = `${urlAPI}?faqAll`;
	axios.get(url).then((res) => {
		setFAQ(res.data[0]);
	});
};
export const incarcaCategorii = (setCategorii, setCategorii2) => {
	let url = `${urlAPI}?posts`;
	axios.get(url).then((res) => {
		const rezultat = res.data;
		setCategorii(rezultat.categorii);
		setCategorii2(rezultat.categorii2);
		console.log(rezultat);
	});
};

// -- website >>>>

// folosit de ultima sub-pagina a informatii studenti
export const incarcaRaspunsIntrebareIS = (informatie, setDocs, setInfo) => {
	const url = `${urlAPI}?informatie=${informatie}`;
	axios.get(url).then((res) => {
		setInfo(res.data[0].informatie);
		setDocs(res.data[0].documente);
	});
};
// folosit de informatii studenti
export const incarcaIntrebariInformatiiStudenti = (categorie, setInfo) => {
	const url = `${urlAPI}?informatii=${categorie}`;
	axios.get(url).then((res) => {
		setInfo(res.data[0]);
	});
};

// folosit de Informatii.js / IntrebariInformatii.js
export const incarcaInformatiiStudenti = (setInfo) => {
	const url = `${urlAPI}?informatii_studenti`;
	axios.get(url).then((res) => {
		setInfo(res.data[0]);
	});
};

/// folosit de editare articol
export const incarcaArticolBlog = (postID, callback) => {
	const url = `${urlAPI}?postare=${postID}`;
	axios.get(url).then((res) => {
		const articol = res.data;
		callback(articol);
		console.log(articol);
	});
};

// folosit de Singlet.js
export const incarcaArticol = (postID, setTime, setArticol, setRelated) => {
	const url = `${urlAPI}?posts&id=${postID}`;
	let rf = new IntlRelativeFormat("ro-RO");
	axios.get(url).then((res) => {
		const articol = res.data;
		setArticol(articol.postari[0]);
		let ago = rf.format(new Date(articol.postari[0].timestamp * 1000));
		setTime(ago);
		setRelated(articol.related);
	});
};

// folosit de pagina /blog
export const incarcaArticoleBlog = (
	setPagini,
	setPostari,
	setCategorii,
	page = 1,
	categorieSelectata = "toate"
) => {
	let url = `${urlAPI}?posts&page=${page}&categorie=${categorieSelectata}&per_page=12`;
	axios.get(url).then((res) => {
		const postari = res.data;
		setPagini(postari.numarPagini);
		setPostari(postari.postari);
		setCategorii(postari.categorii);
	});
};

// folosit de homepage
export const getPosts = (
	update,
	per_page = 6,
	category = "toate",
	callback
) => {
	let url = `${urlAPI}?posts&per_page=${per_page}&categorie=${category}`;
	if (category === "toate") url = `${urlAPI}?posts&per_page=${per_page}`;
	axios
		.get(url)
		.then((res) => {
			const postari = res.data.postari;
			update(postari);
		})
		.then(() => {
			if (callback) callback();
		});
};

const API = () => {};

export default API;
