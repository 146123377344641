import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { getParteneri, stergePartener, getHash } from "../componente/API";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
const columns = [
	{ id: "id", label: "#", minWidth: 40 },

	{ id: "denumire", label: "Denumire", minWidth: 100 },
	{ id: "tip", label: "Tip partner", minWidth: 100 },

	{
		id: "url",
		label: "Link",
		minWidth: 170,
		align: "left",
	},
	{ id: "poza", label: "Poza Light mode", minWidth: 100 },
	{ id: "pozaDark", label: "Poza Dark mode", minWidth: 100 },

	{ id: "modificare", label: "", minWidth: 100 },
];

const useStyles = makeStyles({
	root: {
		width: "100%",
	},
	container: {
		border: "1px solid rgba(0,0,0,.1)",
		marginTop: 30,
	},
});

const Parteneri = () => {
	const [parteneri, setParteneri] = useState("");
	const [tip, setTip] = useState(1);

	useEffect(() => {
		getParteneri((r) => {
			setParteneri(r);
		});
	}, []);
	const classes = useStyles();
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);
	const [denumire, setDenumire] = useState("");
	const [url, setUrl] = useState("");
	const [poza, setPoza] = useState(null);
	const [poza2, setPoza2] = useState(null);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};
	const handlerStergePartener = async (id) => {
		var r = window.confirm("Sigur vrei sa stergi?");
		if (r) {
			stergePartener(id, (r) => {
				console.log(r);
				getParteneri((r) => {
					setParteneri(r);
				});
			});
		}
	};
	const submit = async () => {
		if (denumire === "" || poza === null) return;
		let axiosConfig = {
			headers: {
				"content-type": "multipart/form-data",
			},
		};
		const data = new FormData();
		data.append("denumire", denumire);
		data.append("url", url);
		data.append("poza", poza);
		data.append("poza2", poza2);
		data.append("tip", tip);

		await axios
			.post(
				`https://api.osut.ro/insert.php?token=${getHash()}&partenerNou`,
				data,
				axiosConfig
			)
			.then(function (response) {
				console.log(response.data);
				getParteneri((r) => {
					setParteneri(r);
				});
				setDenumire("");
				setUrl("");
				setPoza("");
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	return (
		<>
			<Grid item xs={12}>
				<Typography variant="h4">Parteneri</Typography>
				<TableContainer className={classes.container}>
					<Table stickyHeader aria-label="sticky table">
						<TableHead>
							<TableRow>
								{columns.map((column) => (
									<TableCell
										key={column.id}
										align={column.align}
										style={{ minWidth: column.minWidth }}>
										{column.label}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{parteneri !== "" &&
								parteneri
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((row) => {
										return (
											<TableRow
												hover
												role="checkbox"
												tabIndex={-1}
												key={row.code}>
												{columns.map((column) => {
													const value = row[column.id];
													return (
														<TableCell key={column.id} align={column.align}>
															{column.format && typeof value === "number" ? (
																column.format(value)
															) : column.id === "poza" ? (
																<img
																	src={row["poza"]}
																	style={{ maxWidth: 90 }}
																/>
															) : (
																value
															)}
															{column.id === "modificare" && (
																<Button
																	variant="contained"
																	color="primary"
																	onClick={() =>
																		handlerStergePartener(row["id"])
																	}>
																	Sterge
																</Button>
															)}
														</TableCell>
													);
												})}
											</TableRow>
										);
									})}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[5, 10, 25]}
					component="div"
					count={parteneri.length}
					rowsPerPage={rowsPerPage}
					page={page}
					labelRowsPerPage="Randuri pe pagina"
					onChangePage={handleChangePage}
					onChangeRowsPerPage={handleChangeRowsPerPage}
				/>
				<small>1 = Partener, 2 = Partener media, 3 = Prieten</small>
				<Grid item xs={12} md={8} style={{ marginTop: 60 }} id="parteneri">
					<div id="insertFAQ">
						<Typography variant="h5" gutterBottom style={{ marginBottom: 25 }}>
							Inserare partener
						</Typography>
						<TextField
							variant="outlined"
							fullWidth={true}
							label="Denumire partener"
							value={denumire}
							onChange={(e) => {
								setDenumire(e.target.value);
							}}
							autoComplete={false}
						/>
						<select
							value={tip}
							onChange={(e) => {
								setTip(e.target.value);
							}}
							variant="contained">
							<option value={1}>Parteneri</option>
							<option value={2}>Parteneri media</option>
							<option value={3}>Prieteni</option>
						</select>
						<TextField
							style={{ marginTop: 20 }}
							variant="outlined"
							fullWidth={true}
							label="URL (optional)"
							autoComplete={false}
							value={url}
							onChange={(e) => {
								setUrl(e.target.value);
							}}
							autoSize={true}
						/>
						<input
							type="file"
							name="file"
							id="file"
							className="inputfile"
							onChange={(e) => {
								setPoza(e.target.files[0]);
							}}
						/>
						<label htmlFor="file" style={{ marginTop: 20 }}>
							{poza ? poza.name : "Light mode PNG"}
						</label>
						<input
							type="file"
							name="file2"
							id="file2"
							className="inputfile"
							onChange={(e) => {
								setPoza2(e.target.files[0]);
							}}
						/>
						<label htmlFor="file2" style={{ marginTop: 0 }}>
							{poza2 ? poza2.name : "Dark mode PNG"}
						</label>

						<Button
							style={{ marginTop: 0 }}
							color="primary"
							size="large"
							onClick={submit}
							variant="contained">
							Trimite
						</Button>
					</div>
				</Grid>
			</Grid>
		</>
	);
};

export default Parteneri;
