import React, { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import {
	createMuiTheme,
	ThemeProvider,
	responsiveFontSizes,
} from "@material-ui/core/styles";
import "./App.css";
import { Route, Switch } from "react-router-dom";
import Home from "./pagini/Home";
import Header from "./componente/Header";
import InsertArticle from "./pagini/InsertArticle";
import InsertInfo from "./pagini/InsertInfo";
import FAQ from "./pagini/FAQ";
import Informatii from "./pagini/Informatii";
import Editare from "./pagini/Editare";
import ListaInformatii from "./pagini/ListaInfo";
import Feedback from "./pagini/Feedback";
import Parteneri from "./pagini/Parteneri";
const App = () => {
	const [dark, switchDark] = useState(true);
	useEffect(() => {
		console.log("app");
	}, [dark]);

	let theme = createMuiTheme({
		palette: {
			type: dark ? "dark" : "light",
			background: {
				paper: dark ? "#1d222e" : "#fefefe",
			},
			primary: {
				main: "#ff4444",
			},
			secondary: {
				main: "#33d",
			},
		},
	});
	theme = responsiveFontSizes(theme);
	return (
		<div>
			<ThemeProvider theme={theme}>
				<Paper
					style={{ borderRadius: 0, boxShadow: "none", minHeight: "100vh" }}
					className={dark ? "dark_mode" : "light_mode"}>
					<Header>
						<Switch basename="/">
							<Route path="/" component={Home} exact />
							<Route path="/feedback" component={Feedback} exact />
							<Route path="/parteneri" component={Parteneri} exact />

							<Route path="/informatii" component={ListaInformatii} exact />
							<Route path="/inserare-articol" component={InsertArticle} exact />
							<Route path="/inserare-informatie" component={InsertInfo} exact />

							<Route path="/faq" component={FAQ} exact />
							<Route path="/informatii-studenti" component={Informatii} exact />
							<Route path="/editare-postare/:id" component={Editare} exact />
						</Switch>
					</Header>
				</Paper>
			</ThemeProvider>
		</div>
	);
};
export default App;
