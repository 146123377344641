import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { getMesajeFeedback } from "../componente/API";

const Feedback = () => {
	const [f, sf] = useState([]);
	useEffect(() => {
		getMesajeFeedback((r) => {
			sf(r);
		});
	});
	return (
		<>
			<Grid item xs={12} md={12}>
				<Typography variant="h4">Feedback articole</Typography>
				{f !== [] &&
					f.map((feedback) => (
						<>
							<div className="mesajFeedback">
								<Typography variant="h6">{feedback.titlu}</Typography>
								<Typography color="textSecondary" variant="body1">
									{feedback.mesaj}
								</Typography>
							</div>
						</>
					))}
			</Grid>
		</>
	);
};

export default Feedback;
