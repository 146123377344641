import React, { useState } from "react";
import Container from "@material-ui/core/Container";
import { GoogleLogin } from "react-google-login";
import { useGoogleLogin } from "react-google-login";
import { Typography } from "@material-ui/core";
import osut from "../img/logo-osut.png";
import { Auth, setHash, getHash } from "../componente/API";

const Login = () => {
	const [rg, srg] = useState(null);
	const [logged, setLogged] = useState(null);

	const responseGoogle = (response) => {
		srg(response);
		console.log(response);
		let token = response.tokenObj.id_token;
		Auth(token, setLogged, (data) => {
			if (data.status === "fail") {
				alert("Se pare ca nu ai acces la aceasta pagina.");
			} else if (data.status === "success") {
				const hash = data.hash;
				setHash(hash);
				window.location.reload();
			}
		});
	};

	return (
		<Container
			component="main"
			maxWidth="md"
			style={{ height: "100%", paddingTop: 50 }}>
			<div style={{ minHeight: "100vh", textAlign: "center" }}>
				{
					<>
						<img
							src={osut}
							alt="osut"
							style={{ height: 90, margin: "20px 0px" }}
						/>
						<Typography color="textPrimary" component="h1" variant="body1">
							Accesul necesita autentificare
						</Typography>
						<GoogleLogin
							clientId="815085222695-jfd6ma2kvpnj2t2aadgek3busucq0obg.apps.googleusercontent.com"
							buttonText="Autentificare cu Google"
							onSuccess={responseGoogle}
							onFailure={responseGoogle}
							cookiePolicy={"single_host_origin"}
						/>
					</>
				}
			</div>
		</Container>
	);
};

export default Login;
