import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import {
	incarcaInformatiiStudenti,
	getHash,
	stergeCategorieInformatii,
	categorieInformatii,
	updateInformatie,
} from "../componente/API";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const Informatii = () => {
	const [categorii, setCategorii] = useState(null);
	const [idEditabil, setIdEditabil] = useState(null);
	const [descriere, setDescriere] = useState("");
	const [denumire, setDenumire] = useState("");
	const [editabil, setEditabil] = useState({ denumire: "", descriere: "" });

	const [open, setOpen] = React.useState(false);
	const resetEditabil = () => {
		setEditabil({ denumire: "", descriere: "" });
	};
	const TabelInformatii = ({ data, setOpen, setIdEditabil }) => {
		return (
			<>
				<table style={{ width: "100%" }}>
					<thead>
						<tr>
							<th>
								<Typography variant="body1" color="textPrimary">
									denumire
								</Typography>
							</th>
							<th>
								<Typography variant="body1" color="textPrimary">
									descriere
								</Typography>
							</th>
							<th></th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{data &&
							data.map((categorie) => {
								return (
									<>
										<tr>
											<td>
												<Typography variant="body1" color="textPrimary">
													{categorie.titlu}
												</Typography>
											</td>
											<td style={{ maxWidth: "300px" }}>
												<Typography
													variant="body2"
													color="textSecondary"
													style={{ fontSize: "12px" }}>
													{categorie.descriere}
												</Typography>
											</td>
											<td style={{ maxWidth: "70px" }}>
												<Button
													variant="contained"
													color="primary"
													onClick={() => {
														if (
															window.confirm(
																"Sigur vrei sa stergi `" +
																	categorie.titlu +
																	"`?"
															)
														)
															stergeCategorieInformatii(
																categorie.id_informatie,
																(r) => {
																	console.log(r);
																	incarcaInformatiiStudenti(setCategorii);
																}
															);
													}}>
													Sterge
												</Button>
											</td>
											<td style={{ maxWidth: "70px" }}>
												<Button
													variant="contained"
													color="secondary"
													onClick={() => {
														setOpen(true);
														setIdEditabil(categorie.id_informatie);
													}}>
													Editeaza
												</Button>
											</td>
										</tr>
									</>
								);
							})}
					</tbody>
				</table>
			</>
		);
	};
	useEffect(() => {
		if (idEditabil !== null) {
			categorieInformatii(idEditabil, (titlu, desc) => {
				setEditabil({ denumire: titlu, descriere: desc });
			});
		}
	}, [idEditabil]);
	const submit = async () => {
		if (denumire === "" || descriere === "") return;
		let axiosConfig = {
			headers: {
				"content-type": "multipart/form-data",
			},
		};

		const data = new FormData();
		data.append("denumire", denumire);
		data.append("descriere", descriere);

		await axios
			.post(
				`https://api.osut.ro/insert.php?token=${getHash()}&informatiiStudenti`,
				data,
				axiosConfig
			)
			.then(function (response) {
				console.log(response);
				incarcaInformatiiStudenti(setCategorii);
				setDenumire("");
				setDescriere("");
			})
			.catch(function (error) {
				console.log(error);
			});
	};
	useEffect(() => {
		incarcaInformatiiStudenti(setCategorii);
	}, []);
	return (
		<>
			<Dialog open={open} aria-labelledby="form-dialog-title">
				<DialogTitle id="form-dialog-title">
					Editeaza categorie (informatii studenti)
					<br />
				</DialogTitle>
				<DialogContent>
					{/* <DialogContentText>...</DialogContentText> */}
					<TextField
						label="Denumire"
						type="text"
						value={editabil.denumire}
						fullWidth
						onChange={(e) => {
							setEditabil({
								descriere: editabil.descriere,
								denumire: e.target.value,
								id: idEditabil,
							});
						}}
						variant="outlined"
					/>
					<br /> <br />
					<br />
					<TextField
						variant="outlined"
						label="descriere"
						rows={3}
						multiline={true}
						type="text"
						value={editabil.descriere}
						onChange={(e) => {
							setEditabil({
								descriere: e.target.value,
								denumire: editabil.denumire,
								id: idEditabil,
							});
						}}
						fullWidth
					/>
					<br /> <br />
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => {
							setOpen(false);
						}}
						color="primary">
						Anulare
					</Button>
					<Button
						onClick={() => {
							updateInformatie(editabil, (r) => {
								console.log(r.data);
								incarcaInformatiiStudenti(setCategorii);
							});
							resetEditabil();
							setOpen(false);
						}}
						color="primary"
						variant="contained">
						Salveaza
					</Button>
				</DialogActions>
			</Dialog>
			<Grid item xs={6} md={12}>
				<Typography variant="h4">Informatii studenti</Typography>
				<Grid container>
					<Grid item xs={12} md={12}>
						<Typography variant="h6" color="textSecondary">
							Categorii
						</Typography>
						<div className="categorieInformatii">
							<TabelInformatii
								data={categorii}
								setIdEditabil={setIdEditabil}
								setOpen={setOpen}
							/>
							<div id="insertCategorieInformatii">
								<Typography variant="h6" gutterBottom>
									Categorie noua
								</Typography>
								<TextField
									variant="outlined"
									fullWidth={true}
									label="Intrebare"
									value={denumire}
									onChange={(e) => {
										setDenumire(e.target.value);
									}}
									autoComplete={false}
								/>
								<TextField
									style={{ marginTop: 20 }}
									variant="outlined"
									fullWidth={true}
									label="Raspuns"
									autoComplete={false}
									multiline={true}
									rows={6}
									value={descriere}
									onChange={(e) => {
										setDescriere(e.target.value);
									}}
									autoSize={true}
								/>
								<Button
									style={{ marginTop: 20 }}
									color="primary"
									size="large"
									onClick={submit}
									variant="contained">
									Inserare
								</Button>
							</div>
						</div>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

export default Informatii;
