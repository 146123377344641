import React, { useState, useEffect } from "react";
import "./home.css";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { incarcaArticoleBlog, stergeArticolBlog, getFeedback } from "./API";
import IntlRelativeFormat from "intl-relativeformat";
import { Link } from "react-router-dom";
const Tr = ({ item, stergeArticol, ago }) => {
	const [feedback, sf] = useState("");
	useEffect(() => {}, [feedback]);
	getFeedback(item.id, (r) => {
		sf(
			<>
				<span style={{ color: "#4EB822" }}>{r["pozitiv"].toString()}</span>
				&nbsp;/&nbsp;
				<span style={{ color: "#FF4444" }}>{r["negativ"].toString()}</span>
			</>
		);
	});
	return (
		<tr>
			<td>
				<Typography variant="body1" color="textSecondary">
					{item.id}
				</Typography>
			</td>
			<td>
				<Typography variant="h6" color="textPrimary">
					{item.titlu}
				</Typography>
			</td>
			<td>
				<Typography variant="h6" color="textPrimary">
					{feedback}
				</Typography>
			</td>
			<td>
				<Typography variant="body1" color="textSecondary">
					{item.denumire_categorie}
				</Typography>
			</td>
			<td>
				<Typography variant="body1" color="textSecondary">
					{ago}
				</Typography>
			</td>
			<td>
				<Link to={"editare-postare/" + item.id}>
					<Button variant="contained" color="secondary">
						Editeaza
					</Button>
				</Link>
				&nbsp;&nbsp;
				<Button
					onClick={() => {
						stergeArticol(item.id);
					}}
					variant="contained"
					color="primary">
					Sterge
				</Button>
			</td>
		</tr>
	);
};
export default function TabelPostari() {
	const [items, setItems] = useState([]);
	const [categorii, setCategorii] = useState([]);
	const [numarPagini, setNumarPagini] = useState(null);
	const [page, setPage] = useState(1);
	const [categorieSelectata, setCategorieSelectata] = useState("toate");

	const stergeArticol = (id) => {
		if (
			window.confirm("Sigur stergi articolul? Aceasta actiune este permanenta")
		)
			stergeArticolBlog(id, (r) => {
				console.log(r);
				incarcaArticoleBlog(
					setNumarPagini,
					setItems,
					setCategorii,
					page,
					categorieSelectata
				);
			});
	};
	useEffect(() => {
		incarcaArticoleBlog(
			setNumarPagini,
			setItems,
			setCategorii,
			page,
			categorieSelectata
		);
		console.log("tabel");
	}, []);

	return (
		<div style={{ width: "100%" }}>
			<table id="tabPostari">
				<thead>
					<tr>
						<th>#</th>
						<th>Titlu</th>
						<th>feedback</th>
						<th>Categorie</th>
						<th>Data</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{items.length !== [] &&
						items.map((item) => {
							let rf = new IntlRelativeFormat("ro-RO");
							let ago = rf.format(new Date(item.edit * 1000));
							return (
								<Tr
									key={item.id}
									item={item}
									ago={ago}
									stergeArticol={stergeArticol}
								/>
							);
						})}
				</tbody>
			</table>
		</div>
	);
}
