import React, { useState, useEffect, useRef } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {
	getInformatii,
	getAtasamenteInformatie,
	stergeInformatieStudenti,
	incarcaInformatiiStudenti,
	updateInformatieStudenti,
	stergeAtasamentInformatii,
} from "../componente/API";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import SunEditor, { buttonList } from "suneditor-react";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
const Tr = ({
	item,
	updateList,
	setIdEdit,
	setContinut,
	setTitlu,
	setAtasamente,
	setCategorie,
	setOpen,
	setAtasamenteSelectate,
	editorRef,
}) => {
	return (
		<tr>
			<td>
				<Typography variant="body1" color="textSecondary">
					{item.id_info}
				</Typography>
			</td>
			<td>
				<Typography variant="h6" color="textPrimary">
					{item.intrebare}
				</Typography>
			</td>

			<td>
				<Typography variant="body1" color="textSecondary">
					{item.titlu}
				</Typography>
			</td>
			<td>
				<Typography variant="body1" color="textSecondary">
					{item.descriere}
				</Typography>
			</td>
			<td>
				<Button
					variant="contained"
					color="secondary"
					onClick={() => {
						setTitlu(item.intrebare);
						setContinut(item.raspuns);
						setCategorie(item.id_informatie);
						setIdEdit(item.id_info);
						setOpen(true);
						getAtasamenteInformatie(item.id_info, (r) => {
							console.log(r);
							setAtasamente(r);
						});
						setAtasamenteSelectate([]);
						window.scrollTo(0, 0);
					}}>
					Editeaza
				</Button>
				&nbsp;&nbsp;
				<Button
					onClick={() => {
						if (
							window.confirm(
								"Sigur doresti sa sterge? Aceasta actiune este ireversibila"
							)
						)
							stergeInformatieStudenti(item.id_info, (r) => {
								console.log(r);
								updateList();
							});
					}}
					variant="contained"
					color="primary">
					Sterge
				</Button>
			</td>
		</tr>
	);
};

export default function ListaInformatii() {
	const [items, setItems] = useState([]);
	const [categorie, setCategorie] = useState(null);
	const [titlu, setTitlu] = useState(null);
	const [continut, setContinut] = useState(null);
	const [atasamente, setAtasamente] = useState([]);
	const [atasamenteSelectate, setAtasamenteSelectate] = useState([]);
	const [selectedAttach, setSelectedAttach] = useState("");

	const [idEdit, setIdEdit] = useState(null);
	const [open, setOpen] = useState(false);
	const [categorii, setCategorii] = useState([]);
	const editorRef = useRef();

	const updateListaAtasamente = () => {
		var input = document.getElementById("atasamente");
		var children = "";
		for (var i = 0; i < input.files.length; ++i) {
			children += "<li>" + input.files.item(i).name + "</li>";
		}
		setSelectedAttach("<ul style='width:100%'>" + children + "</ul>");
	};

	const updateList = () => {
		getInformatii(setItems, (r) => {
			console.log(r);
		});
	};
	useEffect(() => {
		updateList();
		incarcaInformatiiStudenti(setCategorii);
		if (continut) editorRef.current.editor.setContents(continut);
	}, [continut]);

	return (
		<>
			<Grid item xs={12}>
				<Grid item xs={12} style={{ display: open ? "block" : "none" }}>
					<Grid
						container
						spacing={1}
						direction="row"
						alignItems="flex-start"
						style={{ marginBottom: 80 }}>
						<Grid item xs={12} style={{ marginBottom: 20 }}>
							<Typography variant="h4">Editare</Typography>
						</Grid>
						<Grid xs={12} md={8}>
							<TextField
								label="Denumire"
								type="text"
								value={titlu}
								fullWidth
								onChange={(e) => {
									setTitlu(e.target.value);
								}}
								variant="outlined"
								style={{ margin: "10px 0px" }}
							/>
							<SunEditor
								ref={editorRef}
								width="100%"
								placeholder="Conținutul articol"
								onChange={setContinut}
								setOptions={{
									charCounter: true,
									height: "auto",
									minHeight: "600px",
									maxHeight: "6250px",
									buttonList: [
										[
											"undo",
											"redo",
											"formatBlock",
											"bold",
											"underline",
											"italic",
											"strike",
											"image",
											"video",
											"fullScreen",
											"showBlocks",
											"codeView",
											"preview",
											"table",
											"link",
										],
									],
								}}
							/>
						</Grid>
						<Grid xs={12} md={4}>
							<div style={{ paddingLeft: 20 }}>
								<Typography variant="h5">Atasamente</Typography>
								{atasamente !== [] && Array.isArray(atasamente) && (
									<table>
										<thead>
											<tr>
												<td>denumire</td>
												<td>tip</td>
												<td>dimensiune</td>
												<td></td>
											</tr>
										</thead>
										<tbody>
											{Array.isArray(atasamente) &&
												atasamente.map((att) => {
													return (
														<>
															<tr key={att.url}>
																<td>
																	<a
																		href={
																			"https://api.osut.ro/media/docs/" +
																			att.url
																		}
																		title="Click pentru a descarca"
																		style={{ textDecoration: "none" }}>
																		<Typography
																			color="textSecondary"
																			variant="body2">
																			{att.denumire}
																		</Typography>
																	</a>
																</td>
																<td>{att.tip_fisier}</td>
																<td>{att.filesize}</td>
																<td>
																	<Button
																		color="primary"
																		variant="contained"
																		onClick={() => {
																			if (
																				window.confirm(
																					"Sigur stergi atasamentul? Aceasta actiune este permanenta."
																				)
																			) {
																				stergeAtasamentInformatii(
																					att.id_atasament,
																					(r) => {
																						console.log(r);
																						getAtasamenteInformatie(
																							idEdit,
																							(r) => {
																								console.log(r);
																								setAtasamente(r);
																							}
																						);
																					}
																				);
																			}
																		}}>
																		Sterge
																	</Button>
																</td>
															</tr>
														</>
													);
												})}
										</tbody>
									</table>
								)}
								<input
									style={{ marginBottom: 20 }}
									type="file"
									name="atasamente"
									multiple
									id="atasamente"
									onChange={(e) => {
										setAtasamenteSelectate(e.target.files);
										updateListaAtasamente();
									}}
								/>
								<Typography
									color="textPrimary"
									variant="body1"
									gutterBottom
									dangerouslySetInnerHTML={{ __html: selectedAttach }}
								/>
								<br />
								<br />
								<Typography variant="h5">Categorie</Typography>
								<FormControl component="fieldset">
									{/* <FormLabel component="legend">Categorie</FormLabel> */}
									<RadioGroup
										aria-label="gender"
										name="gender1"
										value={categorie}
										onChange={(e) => {
											setCategorie(e.target.value);
										}}>
										{categorii.map((cat) => {
											return (
												<>
													<FormControlLabel
														value={cat.id_informatie}
														control={<Radio />}
														key={cat.id_informatie}
														label={cat.titlu}
													/>
												</>
											);
										})}
									</RadioGroup>
								</FormControl>
								<Button
									size="large"
									fullWidth={true}
									variant="contained"
									color="primary"
									onClick={() => {
										updateInformatieStudenti(
											idEdit,
											titlu,
											continut,
											categorie,
											atasamenteSelectate,
											(r) => {
												console.log(r);
												setContinut(null);
												setOpen(false);
												setAtasamenteSelectate([]);
												updateListaAtasamente();
												setSelectedAttach("");
												updateList();
											}
										);
									}}
									style={{ marginBottom: 10, marginTop: 20 }}>
									Salveaza
								</Button>
								<Button
									fullWidth={true}
									variant="outlined"
									color="default"
									onClick={() => {
										setOpen(false);
										setSelectedAttach("");
										setAtasamenteSelectate([]);
										updateListaAtasamente();
									}}>
									Renunta
								</Button>
							</div>
						</Grid>
					</Grid>
				</Grid>

				<div style={{ width: "100%" }}>
					<Typography variant="h4" style={{ marginBottom: 20 }}>
						Lista informatii studenti
					</Typography>
					<table id="tabPostari">
						<thead>
							<tr>
								<th>#</th>
								<th>intrebare</th>
								<th>categorie</th>
								<th>descriere_categorie</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{items.length !== [] &&
								items.map((item) => {
									return (
										<Tr
											setIdEdit={setIdEdit}
											setContinut={setContinut}
											setTitlu={setTitlu}
											setCategorie={setCategorie}
											setAtasamente={setAtasamente}
											updateList={updateList}
											key={item.id_info}
											item={item}
											setOpen={setOpen}
											setAtasamenteSelectate={setAtasamenteSelectate}
										/>
									);
								})}
						</tbody>
					</table>
				</div>
			</Grid>
		</>
	);
}
