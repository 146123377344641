import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { Grid } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import SunEditor, { buttonList } from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import "../App.css";
import { incarcaCategorii, incarcaInformatiiStudenti } from "../componente/API";
import { getHash } from "../componente/API";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Loader from "../componente/Loader";

const InsertInfo = () => {
	const [title, setTitle] = useState("");
	const [rasp, sr] = useState(null);
	const [categorii, setCategorii] = useState([]);
	const [categorii2, setCategorii2] = useState([]);
	const [selectedAttach, setSelectedAttach] = useState("");
	const [categorieSelectata, setCategorieSelectata] = useState(0);
	const [content, setContent] = useState("");
	const [allCats, setAllCats] = useState(true);
	const [catNoua, setCatNoua] = useState("");
	const [atasamente, setAtasamente] = useState([]);
	const [trimis, setTrimis] = useState(false);

	useEffect(() => {
		incarcaInformatiiStudenti(setCategorii);
	}, []);

	const handleChangeCategorie = (event, value) => {
		setCategorieSelectata(event.target.value);
	};
	const handleCatNoua = (event, value) => {
		setCatNoua(event.target.value);
	};
	const updateList = () => {
		var input = document.getElementById("atasamente");
		var children = "";
		for (var i = 0; i < input.files.length; ++i) {
			children += "<li>" + input.files.item(i).name + "</li>";
		}
		setSelectedAttach("<ul style='width:100%'>" + children + "</ul>");
	};

	const submit = async () => {
		let axiosConfig = {
			headers: {
				"content-type": "multipart/form-data",
			},
		};

		const data = new FormData();
		data.append("titlu", title);
		data.append("continut", content);
		data.append("categorie", categorieSelectata);

		for (let i = 0; i < atasamente.length; i++) {
			data.append("atasamente[]", atasamente[i]);
		}

		await axios
			.post(
				`https://api.osut.ro/insert.php?&informatie_noua&token=${getHash()}`,
				data,
				axiosConfig
			)
			.then(function (response) {
				sr(response.data);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	return (
		<>
			{!trimis ? (
				<>
					<Grid item xs={12} md={8}>
						<Typography variant="h4" style={{ marginBottom: 20 }}>
							Informatie noua
						</Typography>
						<TextField
							id="outlined-basic"
							label="Titlu Articol"
							variant="outlined"
							inputProps={{
								form: {
									autocomplete: "off",
								},
							}}
							key="title"
							style={{ width: "100%", marginBottom: "20px" }}
							onChange={(e) => setTitle(e.target.value)}
						/>
						<SunEditor
							width="100%"
							placeholder="Conținutul articol"
							onChange={setContent}
							setOptions={{
								charCounter: true,
								height: "auto",
								minHeight: "600px",
								maxHeight: "6250px",
								buttonList: [
									[
										"undo",
										"redo",
										"formatBlock",
										"bold",
										"underline",
										"italic",
										"strike",
										"image",
										"video",
										"fullScreen",
										"showBlocks",
										"codeView",
										"preview",
										"table",
										"link",
									],
								],
							}}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						md={4}
						style={{ position: "relative", top: 0, left: 0 }}>
						<h1 style={{ marginBottom: 20 }}>&nbsp;</h1>
						<Typography
							color="textPrimary"
							variant="h5"
							gutterBottom
							component="h5">
							Atasamente
						</Typography>
						<input
							style={{ marginBottom: 20 }}
							type="file"
							name="atasamente"
							multiple
							id="atasamente"
							onChange={(e) => {
								updateList();
								setAtasamente(e.target.files);
								console.log(e.target.files);
							}}
						/>
						<Typography
							color="textPrimary"
							variant="body1"
							gutterBottom
							dangerouslySetInnerHTML={{ __html: selectedAttach }}
						/>
						<Typography
							color="textPrimary"
							variant="h5"
							gutterBottom
							style={{ marginTop: 20 }}
							component="h5">
							Categorie
						</Typography>
						<FormControl component="fieldset">
							{/* <FormLabel component="legend">Categorie</FormLabel> */}
							<RadioGroup
								aria-label="gender"
								name="gender1"
								value={categorieSelectata}
								onChange={handleChangeCategorie}>
								{allCats
									? categorii.map((categorie) => {
											return (
												<>
													<FormControlLabel
														value={categorie.id_informatie}
														control={<Radio />}
														key={categorie.id_informatie}
														label={categorie.titlu}
													/>
												</>
											);
									  })
									: categorii
											.slice(0, 5)
											.map((categorie) => (
												<FormControlLabel
													value={categorie.id_informatie}
													control={<Radio />}
													key={categorie.id_informatie}
													label={categorie.denumire_categorie}
												/>
											))}
							</RadioGroup>
						</FormControl>
						<Button
							style={{ marginTop: 20 }}
							variant="contained"
							fullWidth={true}
							color="primary"
							size="large"
							onClick={() => {
								if (
									title == "" ||
									(content.length < 10) | (categorieSelectata == 0)
								)
									return;
								submit();
								setTrimis(true);
							}}>
							Postează
						</Button>
						cat {categorieSelectata}
						<br />
						titlu {title}
						{content}
						<br />
					</Grid>
				</>
			) : (
				<>
					<Grid item xs={12} md={12} style={{ textAlign: "center" }}>
						{rasp === null ? (
							<>
								<Typography
									variant="h3"
									style={{ marginTop: 120, marginBottom: 20, fontWeight: 100 }}>
									Se încarcă...
								</Typography>
								<Loader />
							</>
						) : (
							<h1>
								{rasp.status === "fail"
									? "A aparut o problema."
									: "Succes, informatia a fost publicata pe site"}
							</h1>
						)}
					</Grid>
				</>
			)}
		</>
	);
};

export default InsertInfo;
