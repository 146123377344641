import { useParams } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { Grid } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Post from "../componente/Post";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import "../App.css";
import { incarcaCategorii } from "../componente/API";
import TagsInput from "react-tagsinput";
import {
	getHash,
	incarcaArticolBlog,
	stergeAtasamentBlog,
} from "../componente/API";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

const Hint = ({ value }) => (
	<p style={{ marginTop: -10, color: "#aaa", marginBottom: 15 }}>
		<small>{value}</small>
	</p>
);
const AdaugareCategorie = ({ trimiteCategorieNoua }) => {
	return (
		<Button
			color="primary"
			variant="contained"
			onClick={() => {
				trimiteCategorieNoua();
			}}>
			Trimite
		</Button>
	);
};
const Editare = (props) => {
	let { id } = useParams();
	const editorRef = useRef();
	const [title, setTitle] = useState("");
	const [desc, setDesc] = useState("");
	const [rasp, sr] = useState("");
	const [image, setImage] = useState([]);
	const [poza, setpoza] = useState("");
	const [categorii, setCategorii] = useState([]);
	const [categorii2, setCategorii2] = useState([]);
	const [selectedAttach, setSelectedAttach] = useState("");

	const [categorieSelectata, setCategorieSelectata] = useState(1);
	const [content, setContent] = useState("");
	const [tags, setTags] = useState([]);
	const [allCats, setAllCats] = useState(true);
	const [catNoua, setCatNoua] = useState("");
	const [atasamente, setAtasamente] = useState([]);
	const [attVechi, setAttVechi] = useState([]);
	const incarcaArticol = () => {
		incarcaArticolBlog(
			id,
			({
				titlu,
				descriere_scurta,
				categorie,
				continut,
				poza,
				tags,
				atasamente,
			}) => {
				setCategorieSelectata(categorie);
				setContent(continut);
				setDesc(descriere_scurta);
				setTitle(titlu);
				setAttVechi(atasamente);
				//console.log(atasamente);
				setTags([tags]);
				setpoza(poza);
				editorRef.current.editor.setContents(continut);
			}
		);
	};
	useEffect(() => {
		incarcaCategorii(setCategorii, setCategorii2);
		incarcaArticol();
	}, []);

	const handleChangeCategorie = (event, value) => {
		setCategorieSelectata(event.target.value);
	};
	const handleCatNoua = (event, value) => {
		setCatNoua(event.target.value);
	};
	const updateList = () => {
		var input = document.getElementById("atasamente");
		var children = "";
		for (var i = 0; i < input.files.length; ++i) {
			children += "<li>" + input.files.item(i).name + "</li>";
		}
		setSelectedAttach("<ul style='width:100%'>" + children + "</ul>");
	};

	const trimiteCategorieNoua = async () => {
		let axiosConfig = {
			headers: {
				"content-type": "multipart/form-data",
			},
		};
		const data = new FormData();
		data.append("categorieNoua", catNoua);

		await axios
			.post(
				`https://api.osut.ro/insert.php?token=${getHash()}`,
				data,
				axiosConfig
			)
			.then(function (response) {
				//console.log(response.data);
				incarcaCategorii(setCategorii, setCategorii2);
				setCatNoua("");
			})
			.catch(function (error) {
				console.error(error);
				setCatNoua("");
			});
	};

	const submit = async () => {
		let axiosConfig = {
			headers: {
				"content-type": "multipart/form-data",
			},
		};

		const data = new FormData();
		data.append("poza", image);
		data.append("desc", desc);
		data.append("titlu", title);
		data.append("continut", content);
		data.append("categorie", categorieSelectata);
		data.append("etichete", tags);
		for (let i = 0; i < atasamente.length; i++) {
			data.append("atasamente[]", atasamente[i]);
		}

		await axios
			.post(
				`https://api.osut.ro/update.php?editeazaArticol=${id}&token=${getHash()}`,
				data,
				axiosConfig
			)
			.then(function (response) {
				//console.log(response);
				sr(response.data);
				//console.log(image);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	return (
		<>
			<Grid item xs={12} md={8}>
				<Typography variant="h4" style={{ marginBottom: 40 }}>
					Editare articol
				</Typography>
				<TextField
					id="outlined-basic"
					label="Titlu Articol"
					variant="outlined"
					inputProps={{
						maxLength: 83,
						form: {
							autocomplete: "off",
						},
					}}
					value={title}
					key="title"
					style={{ width: "100%", marginBottom: "20px" }}
					onChange={(e) => setTitle(e.target.value)}
				/>
				<TextField
					id="outlined-basic"
					label="Descriere scurtă / rezumat"
					key="desc"
					variant="outlined"
					multiline={true}
					rows={4}
					value={desc}
					style={{ width: "100%", marginBottom: "20px" }}
					onChange={(e) => setDesc(e.target.value)}
				/>

				<SunEditor
					ref={editorRef}
					width="100%"
					placeholder="Conținutul articol"
					onChange={setContent}
					setOptions={{
						charCounter: true,
						height: "auto",
						minHeight: "600px",
						maxHeight: "6250px",
						buttonList: [
							[
								"undo",
								"redo",
								"formatBlock",
								"bold",
								"underline",
								"italic",
								"strike",
								"image",
								"video",
								"fullScreen",
								"showBlocks",
								"codeView",
								"preview",
								"table",
								"link",
							],
						],
					}}
				/>
			</Grid>
			<Grid
				item
				xs={12}
				md={4}
				style={{ position: "relative", top: 0, left: 0 }}>
				<h1 style={{ marginBottom: 20 }}>&nbsp;</h1>
				<Typography
					color="textPrimary"
					variant="h5"
					gutterBottom
					component="h5">
					Poză articol
				</Typography>
				<input
					type="file"
					name="file"
					id="file"
					className="inputfile"
					onChange={(e) => {
						setImage(e.target.files[0]);
						setpoza(URL.createObjectURL(e.target.files[0]));
						console.log(e);
					}}
				/>
				<label htmlFor="file">
					{image.name ? image.name : "Selectează fișier PNG sau JPG"}
				</label>
				<Typography
					color="textPrimary"
					variant="h5"
					gutterBottom
					component="h5">
					Atasamente
				</Typography>
				{attVechi !== [] && Array.isArray(attVechi) && (
					<table>
						<thead>
							<tr>
								<td>denumire</td>
								<td>tip</td>
								<td>dimensiune</td>
								<td></td>
							</tr>
						</thead>
						<tbody>
							{Array.isArray(attVechi) &&
								attVechi.map((att) => {
									return (
										<>
											<tr key={att.url}>
												<td>
													<a
														href={"https://api.osut.ro/media/docs/" + att.url}
														style={{ textDecoration: "none" }}>
														<Typography color="textSecondary" variant="body2">
															{att.denumire}
														</Typography>
													</a>
												</td>
												<td>{att.tip_fisier}</td>
												<td>{att.filesize}</td>
												<td>
													<Button
														color="primary"
														variant="contained"
														onClick={() => {
															if (
																window.confirm(
																	"Sigur stergi atasamentul? Aceasta actiune este permanenta."
																)
															) {
																stergeAtasamentBlog(att.id_atasament, (r) => {
																	console.log(r);
																	incarcaArticol();
																});
															}
														}}>
														Sterge
													</Button>
												</td>
											</tr>
										</>
									);
								})}
						</tbody>
					</table>
				)}
				<Hint value="Doar pdf, zip, doc sau docx" />
				<input
					style={{ marginBottom: 20 }}
					type="file"
					name="atasamente"
					multiple
					id="atasamente"
					onChange={(e) => {
						updateList();
						setAtasamente(e.target.files);
						//console.log(e.target.files);
					}}
				/>
				<Typography
					color="textPrimary"
					variant="body1"
					gutterBottom
					dangerouslySetInnerHTML={{ __html: selectedAttach }}
				/>
				<Typography
					color="textPrimary"
					variant="h5"
					gutterBottom
					style={{ marginTop: 20 }}
					component="h5">
					Categorie
				</Typography>
				<Hint value="★ = are articole" />
				<FormControl component="fieldset">
					{/* <FormLabel component="legend">Categorie</FormLabel> */}
					<RadioGroup
						aria-label="gender"
						name="gender1"
						value={categorieSelectata}
						onChange={handleChangeCategorie}>
						{allCats
							? categorii.map((categorie) => {
									// verifica daca exista articole in categorie
									let areArticole = "";
									categorii2.filter((cat) => {
										if (cat.id_categorie === categorie.id_categorie)
											areArticole = " ★";
									});

									return (
										<>
											<FormControlLabel
												value={categorie.id_categorie}
												control={<Radio />}
												key={categorie.id_categorie}
												label={categorie.denumire_categorie + "" + areArticole}
											/>
										</>
									);
							  })
							: categorii
									.slice(0, 5)
									.map((categorie) => (
										<FormControlLabel
											value={categorie.id_categorie}
											control={<Radio />}
											key={categorie.id_categorie}
											label={categorie.denumire_categorie}
										/>
									))}
					</RadioGroup>
				</FormControl>
				<Typography
					color="textPrimary"
					variant="body1"
					gutterBottom
					style={{ marginTop: 10 }}
					component="p">
					Categorie noua
				</Typography>
				<TextField
					placeholder="Denumire categorie"
					onChange={handleCatNoua}
					value={catNoua}
					fullWidth={true}
					variant="outlined"
					style={{ width: "100%", marginBottom: "0px" }}
					InputProps={{
						endAdornment: (
							<AdaugareCategorie trimiteCategorieNoua={trimiteCategorieNoua} />
						),
					}}
				/>
				<Typography
					color="textPrimary"
					variant="h5"
					gutterBottom
					style={{ marginTop: 15 }}
					component="h5">
					Etichete
				</Typography>
				<Hint value="enter = inserare eticheta" />
				<TagsInput value={[...tags]} onChange={setTags} />
				{title !== "" && (
					<>
						<Typography
							color="textPrimary"
							variant="h5"
							style={{
								marginTop: 15,
								borderTop: "1px solid rgba(0,0,0,.1)",
								paddingTop: 15,
							}}
							gutterBottom
							component="h5">
							Previzualizare
						</Typography>
						<Post
							test="false"
							type="short"
							titlu={title}
							descriere_scurta={desc}
							id_autor="asdas"
							continut="asdasdas"
							id="asdasdas"
							denumire_categorie="Categorie"
							poza={poza}
						/>
					</>
				)}
				<Button
					style={{ marginTop: 20 }}
					variant="contained"
					fullWidth={true}
					color="primary"
					size="large"
					onClick={() => submit()}>
					Postează articol
				</Button>
				Raspuns request: {rasp}
				{/* <Typography
					color="textPrimary"
					variant="h5"
					gutterBottom
					component="h5">
					Dev
				</Typography>
			
				<br />
				Titlu: {title}
				<br />//
				///dasdasdasdasdassadasdasdsdsadsa
				Continut: {content} <br />
				Descriere {desc} */}
			</Grid>
		</>
	);
};

export default Editare;
